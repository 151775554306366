<template>
    <div class="warp">
        <div class="header">
            <span>请选择您的学习方向</span>
            <van-icon @click="close" class="close" name="cross" />
        </div>

        <div class="bodyWarp">
            <div class="dirWarp">
                <template v-for="(item,index) in list">
                    <span @click="selectCoach(item)" class="dirList" :class="item.id === activeId?'active':''" :key="item.id">{{ item.name }}</span>
                </template>
            </div>

            <div class="footer">
                <van-button class="btn" :class="activeBtn?'activeBtn':''" @click="sub">请选择学习方向</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { getQuestionList, setFirstTypeId } from "@/api/questionBank.js";
import { mapMutations } from 'vuex'

export default {
    data() {
        return {
            list: [],
            activeBtn: false,
            activeId: '',
            activeObj: {}
        }
    },
    name: 'directionList',
    created() {
        window.localStorage.setItem('firstId',137)
        window.localStorage.setItem('learningName', '初级会计师');
        this.getQuestionList();
    },
    methods: {
        ...mapMutations(['setDirection']),

        // 获取学习方向
        getQuestionList() {
            getQuestionList().then((res) => {
                // console.log('获取学习方向---', res)
                if (res.data.code === 0) {
                    this.list = res.data.data;
                }
            })
        },

        selectCoach(item) {
            this.activeBtn = true;
            this.activeId = item.id;
            this.activeObj = item;
            this.$forceUpdate();
        },
        sub() {
            console.log('id-------------',this.activeObj.id)
            if (!this.activeObj.id) return this.$toast('请先选择学习方向')

          setFirstTypeId(this.activeObj.id).then(res=>{
            if(res.data.code == 0){
                // 存储到本地
                window.localStorage.setItem('firstId', this.activeObj.id);
                window.localStorage.setItem('learningName', this.activeObj.name);
                // 存储到vuex
                this.$store.commit('setDirection', { firstId: this.activeObj.id, learningName: this.activeObj.name })
                
                if(this.$route.params.from && this.$route.params.from == 'homeGrid') {
                    this.$router.push('/class/'+ this.activeObj.id)
                }else if(this.$route.params.from){
                    this.$router.push('/'+ this.$route.params.from);
                }
                else{
                    this.$router.push('/learning/direction' );
                }
                
            }
            
          })
        },
        close() {
            this.$router.go(-1);
        },

    },

}
</script>

<style scoped>
.header {
    text-align: center;
    margin-top: 17px;
    position: relative;
}

.header span {
    height: 27px;
    font-size: 19px;
    display: inline-block;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
    line-height: 27px;
}
.bodyWarp {
    height: calc(100vh - 46px);
}
.close {
    font-size: 17px;
    position: absolute;
    right: 14px;
    top: 5px;
    color: #666666;
}

.dirWarp {
    margin-top: 40px;
    padding: 0 14px;
}

.dirList {
    text-align: center;
    display: inline-block;
    width: 101px;
    height: 29px;
    background: #f8f8f8;
    border-radius: 15px;
    margin-bottom: 14px;
    margin-right: 18px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #333333;
    line-height: 29px;
    border: 1px solid transparent;
}

.active {
    color: #5d7dff;
    background: #e7ecff;
    border: 1px solid #5d7dff;
}

.dirWarp .dirList:nth-child(3n + 3) {
    margin-right: 0;
}

.footer {
    padding: 0 14px;
    position: fixed;
    bottom: 52px;
}

.btn {
    width: 347px;
    height: 41px;
    background: #f4f2f2;
    border-radius: 20px;
    color: #333333;
    font-size: 14px;
}

.activeBtn {
    background: #5d7dff;
    color: #ffffff;
}
</style>
